<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-row>
        <v-col md="2" class="mr-2">
          <v-btn v-if="returnAbility('user:store')" color="green" class="white--text"
            @click="$router.push('/addUser/admin')">
            <v-icon size="20">mdi-plus</v-icon>
            {{ $t("Add User") }}
          </v-btn>
          <!-- <app-add-subject @submitted="fetchData(1)"></app-add-subject> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" v-model="name" :label="$t('search by name')" dense outlined hide-details></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" v-model="username" :label="$t('search by user name')" dense outlined
            hide-details></v-text-field>
        </v-col>
        <v-col md="3" >
          <v-text-field :dark="$store.state.isDarkMode" v-model="phone" :label="$t('search by phone number')" dense outlined
            hide-details></v-text-field>
        </v-col>
        <v-col md="3">
          <AutoCompleteField :label="$t('Role')" endPoint="/role/auto-complete" v-model="role_id" outlined/>
        </v-col>
        <v-col md="3" >
          <AutoCompleteField :label="$t('School Name')" endPoint="/school/auto-complete" v-model="school_name" outlined/>
        </v-col>
        <v-col>
          <v-icon large :dark="$store.state.isDarkMode" class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
        </v-col>
      </v-row>

      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData" serverRoute="/user"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="displayDeleteIcon" :flaggedItems="flaggedItemsData">
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon v-if="returnAbility('user:update')" color="info"
            @click="$router.push(`/editUser/admin/${row.id}`)">mdi-pencil</v-icon>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
export default {
  components: {
    AutoCompleteField,
  },
  data() {
    return {
      submitBtnLoading: false,
      // filter
      name: "",
      phone: null,
      username: "",
      role_id: null,
      school_name: null,
      flaggedItemsData: [
        "created_at",
        "email_verified_at",
        "image",
        "token",
        "type",
        "updated_at",
      ],
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "admins/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchAdmins: "admins/fetchAdmins",
    }),

    fetchData(page) {
      this.fetchAdmins({
        params: {
          page,
          name: this.name,
          phone: this.phone,
          username: this.username,
          role_id: this.role_id,
          school_name: this.school_name,
        },
      });
    },
  },
  created() {
    if (this.returnAbility("user:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style></style>
